import React, {useEffect, useState} from "react"
import { graphq, Link } from "gatsby"
import Layout from '../components/layout'
import view from "../components/blogPage/blog.module.scss";


const SingleCategory = (props) => {
  const {pageContext} = props;
  const tag = pageContext.tagName;
  const posts = pageContext.searchedPosts;
  const blogManagement = pageContext.blogmanagement;
  const allBlogPagesBottomHTML = blogManagement.allBlogPagesBottomHTML;
  const categoriesBlogResultsHTML = blogManagement.categoriesBlogResultsHTML;
  const blogResultsTopHTML = blogManagement.blogResultsTopHTML;
  const pageSeo = {seo:{
    MetaDesc: `All blog post results for ${pageContext.dealerName} related to ${tag}.`,
    MetaTitle: `${tag} Blog Posts | ${pageContext.dealerName}`,
    tags: []}
  }

  return(
    <Layout seo={pageSeo}>
      <div dangerouslySetInnerHTML={{ __html: blogResultsTopHTML }} />
      <div style={{ width: "95%", margin: '0 auto', maxWidth: 1100, padding: "0 15px"}}>
        <h1 style={{textAlign:'center'}}>Showing posts in <span  className={`${view['tag-title']}`}>{tag}</span></h1>
        <ul style={{display: 'flex', flexWrap:'wrap'}}>
          {posts.map(post => {
            
            const title = JSON.parse(post.pageTitle);
            const slug = JSON.parse(post.slug); 
            const content = JSON.parse(post.content);
            const id = JSON.parse(post._id);
            const parsedContent = content.blogposts;

            return(
              <li key={id} style={{flexBasis: '33.333333%', color:'#232323', padding:10}}>
                <Link style={{height:300, display:'block',boxShadow:'0 0 5px #999', color:'#232323'}} href={slug}>
                  
                  <img style={{display:'block', width:'100%'}} src={`${parsedContent.thumbnailImage}`} />
                  <div style={{ padding:10 }}>
                    <h2 style={{fontSize:18, marginTop:5}}>{title}</h2>
                    <p style={{textAlign:'left', fontStyle:'italic'}}>Posted on: {parsedContent.publicationDate}</p>
                  </div>
                </Link>
              </li>
            )
          })}
        </ul>
      </div>
      {/*BLOG RESULTS DATA */}
      <div dangerouslySetInnerHTML={{ __html: categoriesBlogResultsHTML }} />

      {/* ALL BLOG DATA */}
      <div dangerouslySetInnerHTML={{ __html: allBlogPagesBottomHTML }} />
    </Layout>
  )
}

export default SingleCategory;